import React, { useState, createContext, useContext } from 'react';
import { IntlProvider } from 'react-intl';
// import type { Language } from '../i18n/config/languages';

import '../i18n/config/reactIntl';

/* export interface PageContext {
    id: string;
    locale: Language['locale'];
    originalPath: string;
    languages: Language[];
    intlMessages: Record<string, string>;
    positionId: string;
} */

const PageContext = createContext(
    /* <Omit<PageContext, 'intlMessages'>> */ {} /*  as Omit<PageContext, 'intlMessages'> */,
);

export const usePageContext = () => {
    const context = useContext(PageContext);

    return context;
};

export const PageContextProvider = ({ children, ...props }) => {
    const [locale, setLocale] = useState(props.pageContext.locale);
    const [messages, setMessages] = useState();

    const { intlMessages, ...pageContext } = props.pageContext;

    return (
        <IntlProvider locale={locale} messages={messages || intlMessages}>
            <PageContext.Provider value={pageContext}>
                {React.Children.map(children, child =>
                    React.cloneElement(child, {
                        ...props,
                        setLocale,
                        setMessages,
                    }),
                )}
            </PageContext.Provider>
        </IntlProvider>
    );
};
